<template>
  <v-container class="pt-0">
    <p v-if="selectedFiltersChips.length == 0" class="mb-0 text--disabled">
      {{ $t("collection.advance_search.noFilters") }}
    </p>
    <div v-else class="d-flex">
      <div :class="$vuetify.breakpoint.xs ? 'overflow' : ''">
        <template v-for="selectedFilter in selectedFiltersChips">
          <v-chip
            class="mr-2 mb-2"
            :key="selectedFilter.value"
            close
            :v-model="selectedFilter"
            @click:close="removeFilter(selectedFilter)"
          >
            <span
              :title="`${$t(selectedFilter.metadata)} : ${selectedFilter.text}`"
              class="text-truncate"
            >
              {{ $t(selectedFilter.metadata) + ": " + selectedFilter.text }}
            </span>
          </v-chip>
        </template>
      </div>
      <div class="ml-auto">
        <v-btn @click="removeAllFilters()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <v-divider class="mt-2"></v-divider>
    <small>{{ $t("collection.advance_search.selectedFilters") }}</small>
  </v-container>
</template>

<script>
//import { mapGetters } from "vuex";

export default {
  props: ['localFilters','queryFilters'],
  computed: {
    //...mapGetters(["localFilters", "queryFilters"]),
    selectedFiltersChips() {
      let selectedFilters = [];
      let filters = {};

      if(this.queryFilters.docstatus) filters.docstatus = this.queryFilters.docstatus
      if(this.queryFilters.doctopicList) filters.doctopic = this.queryFilters.doctopicList.map(doctopic => doctopic.id)
      if(this.queryFilters.doctypeList) filters.doctype = this.queryFilters.doctypeList.map(doctype => doctype.id)
      if(this.queryFilters.roleList) filters.role = this.queryFilters.roleList.map(role => role.id)
      if(this.queryFilters.path) filters.ident = this.queryFilters.path

      if (!this.queryFilters.selAllDate) {
        filters = {
          ...filters,
          date: [
            `${this.queryFilters.date[0]} - ${this.queryFilters.date[1]}`,
          ],
        };
      }

      for (let filterCategory in filters) {
        if (filters[filterCategory].length > 0) {
          for (let valueFilter of filters[filterCategory]) {
            let filter = {
              category: "",
              value: null,
              metadata: "",
              text: "",
            };
            let split, section, volume, tome;
            filter.value = valueFilter;
            filter.category = filterCategory;

            switch (filterCategory) {
              case "ident":
                split = valueFilter.split("_");
                section = split[0];
                volume = split[1];
                tome = split[2];

                filter.metadata = `collection.advance_search.structure.title`;
                filter.text = `S${section}-V${volume}-T${tome}`;
                // filter.text = `${this.$t(`collection.editorialPlan.section.stepper`)} ${section}-${this.$t(`collection.editorialPlan.volume.stepper`)} ${volume}-${this.$t(`collection.editorialPlan.tome.stepper`)} ${tome}`;
                break;
              case "date":
                filter.metadata = `collection.advance_search.${filterCategory}.title`;
                filter.text = `${valueFilter}`;
                break;
              default:
                filter.metadata = `collection.advance_search.${filterCategory}.title`;
                filter.text = this.$t(
                  `collection.advance_search.${filterCategory}.list.${valueFilter}`
                );
                break;
            }
            selectedFilters.push(filter);
          }
        }
      }

      if (this.localFilters.title) {
        selectedFilters.push({
          category: "title",
          value: this.localFilters.title,
          metadata: "collection.advance_search.searchTitle.title",
          text: this.localFilters.title,
        });
      }

      if(this.localFilters.keywords){
        if (this.localFilters.keywords.length > 0) {
          this.localFilters.keywords.forEach(key => {
            selectedFilters.push({
            category: "keywords",
            value: key,
            metadata: "collection.advance_search.keywords.title",
            text: key,
            });
          });
        }
      }
      

      return selectedFilters;
    },
  },
  methods: {
    async removeFilter(removedFilter) {
      if(this.$route.path.includes('indexes')) await this.$store.dispatch('indexFilters/removeFilter', removedFilter)
      else {
        await this.$store.dispatch("removeFilter", removedFilter);
      }      
    },
    async removeAllFilters() {
      if(this.$route.path.includes('indexes')) await this.$store.dispatch('indexFilters/resetFilters')
      else {
        await this.$store.dispatch("removeAllFilter");
      }
    },
  },
};
</script>