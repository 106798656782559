<template>      
        <v-treeview
          v-model="path"
         
          selectable
          selected-color="primary"
          :items="structure"
          item-key="id"
        >
          <template v-slot:label="{ item }">
            {{ $t(item.name) }}
          </template>
        </v-treeview>
</template>

<script>
export default {
  props: {
      filtersPath: Array,
      structure: {
          type: Array,
          default: () => [
        {
          id: "1",
          name: "collection.editorialPlan.section-1.id",
          children: [
            {
              id: "1_1_0",
              name: "collection.editorialPlan.section-1.vol-1.id",
            },
            {
              id: "1_2",
              name: "collection.editorialPlan.section-1.vol-2.id",
              children: [
                {
                  id: "1_2_1",
                  name: "collection.editorialPlan.section-1.vol-2.tome.tome-1.id",
                },
                {
                  id: "1_2_2",
                  name: "collection.editorialPlan.section-1.vol-2.tome.tome-2.id",
                },
              ],
            },
            {
              id: "1_3",
              name: "collection.editorialPlan.section-1.vol-3.id",
              children: [
                {
                  id: "1_3_1",
                  name: "collection.editorialPlan.section-1.vol-3.tome.tome-1.id",
                },
                {
                  id: "1_3_2",
                  name: "collection.editorialPlan.section-1.vol-3.tome.tome-2.id",
                },
              ],
            },
            {
              id: "1_4",
              name: "collection.editorialPlan.section-1.vol-4.id",
              children: [
                {
                  id: "1_4_1",
                  name: "collection.editorialPlan.section-1.vol-4.tome.tome-1.id",
                },
                {
                  id: "1_4_2",
                  name: "collection.editorialPlan.section-1.vol-4.tome.tome-2.id",
                },
              ],
            },
          ],
        },
        {
          id: "2",
          name: "collection.editorialPlan.section-2.id",
          children: [
            {
              id: "2_1_0",
              name: "collection.editorialPlan.section-2.vol-1.id",
            },
            {
              id: "2_2_0",
              name: "collection.editorialPlan.section-2.vol-2.id",
            },
            {
              id: "2_3_0",
              name: "collection.editorialPlan.section-2.vol-3.id",
            },
            {
              id: "2_4_0",
              name: "collection.editorialPlan.section-2.vol-4.id",
            },
          ],
        },
      ],
      }
  },
  computed: {
    path: {
      get() {
        return this.filtersPath;
      },
      set(value) {
        return this.$emit("changed-path", value);
      },
    },
  },
};
</script>

<style lang="scss">
.filter-content {
  height: 150px;
  overflow-y: scroll;
}
</style>