<template>
  <div class="d-flex align-center">
    <v-range-slider
      v-model="localDate"
      :max="max"
      :min="min"
      hide-details
      :class="selDate ? 'disable-event' : ''"
      @end="setDate"
      class="align-center mr-2"
    >
      <template v-slot:prepend>
        <v-text-field
          :value="localDate[0]"
          class="mt-0 pt-0"
          hide-details
          single-line
          type="number"
          style="width: 60px"
          @change="$set(localDate, 0, $event)"
        ></v-text-field>
      </template>
      <template v-slot:append>
        <v-text-field
          :value="localDate[1]"
          class="mt-0 pt-0"
          hide-details
          single-line
          type="number"
          style="width: 60px"
          @change="$set(localDate, 1, $event)"
        ></v-text-field>
      </template>
    </v-range-slider>
    <v-checkbox
      v-model="selDate"
      label="Nessuna data"
      color="primary"
      hide-details
      @change="setDate"
    ></v-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    setStartFunction: Function,
    setEndFunction: Function,
    endFunction: Function,
    date: {
      type: Array,
      default: () => [1930, 1978],
    },
    min: {
      type: Number,
      default: 1930,
    },
    max: {
      type: Number,
      default: 1978,
    },
    selAllDate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localDate: this.date,
      selDate: this.selAllDate
    }
  },
  methods: {
    setDate(){            
      let date = this.localDate;
      let selDate = this.selDate;

      return this.$emit('set-date',{date,selDate})
    }
  }
};
</script>

<style lang="scss" scoped>
  .disable-event {
  pointer-events: none;
  opacity: 0.5;
}
</style>